const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.riotinto.forwoodsafety.com',
    APP_CLIENT_ID: '7fi0nuo26cr8le6527o9g7gri8',
    APIGATEWAY: 'https://wzmc3sfhh4.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://hs6cigpyyi.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'testing',
    VERSION: 'actions-container-1.7.0',
    HOSTNAME: 'https://actions.testing.riotinto.forwoodsafety.com',
    ACTIONS_ACCESS_ROLE: 'ActionsAccess',
    ACTION_PLAN_LIST_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/ccps_action_plan_responsible_report',
    ACTION_PLAN_DASHBOARD_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/action-plan-dashboard',
    ACTION_PLAN_BULK_EDIT_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/ccps-action-plan-bulk-edit',
    IFRAME_ORIGIN_URL: 'https://iframe.testing.riotinto.forwoodsafety.com',
    CRM_HOMEPAGE_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/?v4_app=actions'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
